@font-face {
    font-family: 'Digital-7';
    src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/184191/Digital-7.eot?#iefix') format('embedded-opentype'),  
    url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/184191/Digital-7.woff') format('woff'), 
    url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/184191/Digital-7.ttf')  format('truetype'), 
    url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/184191/Digital-7.svg#Digital-7') format('svg');
    font-weight: normal;
    font-style: normal;
}
    
 
.digital-clock{
    figure {
        width: 110px;
        height: 110px;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        -webkit-transform: rotateX(-35deg) rotateY(45deg);
        transform: rotateX(-35deg) rotateY(45deg);
        transition: 2s;
        &:hover {
            -webkit-transform: rotateX(-50deg) rotateY(45deg);
            transform: rotateX(-50deg) rotateY(45deg);
        }
    }
    .face {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-transform-origin: center;
        transform-origin: center;
        background: #000;
        text-align: center;
    }
    p {
        font-size: 80px;
        font-family: 'Digital-7';
        margin-top: 20px;
        color: #2982FF;
        text-shadow: 0px 0px 5px #000;
        -webkit-animation: color 10s infinite;
        animation: color 10s infinite;
        line-height: 80px;
    }
    .front {
        -webkit-transform: translate3d(0, 0, 55px);
        transform: translate3d(0, 0, 55px);
        background: #111;
    }
    .left {
        -webkit-transform: rotateY(-90deg) translate3d(0, 0, 55px);
        transform: rotateY(-90deg) translate3d(0, 0, 55px);
        background: #151515;
    }
    .top {
        -webkit-transform: rotateX(90deg) translate3d(0, 0, 55px);
        transform: rotateX(90deg) translate3d(0, 0, 55px);
        background: #222;
    }

    @keyframes color{
    0%{color:#2982ff;text-shadow:0px 0px 5px #000;}
    50%{color:#cc4343;text-shadow:0px 0px 5px #ff0000;}
    }
    @-webkit-keyframes color{
    0%{color:#2982ff;text-shadow:0px 0px 5px #000;}
    50%{color:#cc4343;text-shadow:0px 0px 5px #ff0000;}
    }
}
