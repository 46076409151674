/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "../node_modules/angular-calendar/css/angular-calendar.css";
// @import "~bootstrap/dist/css/bootstrap.min.css";

$custom-typography: mat.define-typography-config($font-family: 'Poppins');

@include mat.core($custom-typography);
html,
body {
  height: 100%;
  width: 100%;
  /*min-height: 643px;*/
  min-width: 100% !important;
  overflow: hidden;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
  color: #4a4a4a; 
}

body {
  overflow: auto !important;
}
*{
    margin: 0;
    padding: 0;
}
body{
  background: none;
}
snack-bar-container{
  background: #000;
  color: #FFF;
}
.bg-main {
  // background: radial-gradient(circle at center, #5d5c5a 20%, #999999 70%);
  // height: 100vh;
  // overflow: hidden !important;
  // width: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: -3;
  // background-size: 400% 400%;
	// animation: gradient 10s ease infinite;
  background-image: url("assets/images/background.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg{
  background-image: url("assets/images/bg-inside.jpg");
  // -ms-transform: rotate(180deg); /* IE 9 */
  // transform: rotate(180deg);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -3;
  opacity: 0.5;
  // background-size: 400% 400%;
}
// .bg:before {
//   content: "";
//   width: 100%;
//   height: 100%;
//   // background: #000;
//   position: fixed;
//   z-index: -1;
//   top: 0;
//   left: 0;
//   // opacity: 0.3;
// }
@keyframes sf-fly-by-1 {
  from {
      transform: translateZ(-600px);
      opacity: 0.5;
  }
  to {
      transform: translateZ(0);
      opacity: 0.5;
  }
}
@keyframes sf-fly-by-2 {
  from {
      transform: translateZ(-1200px);
      opacity: 0.5;
  }
  to {
      transform: translateZ(-600px);
      opacity: 0.5;
  }
}
@keyframes sf-fly-by-3 {
  from {
      transform: translateZ(-1800px);
      opacity: 0.5;
  }
  to {
      transform: translateZ(-1200px);
      opacity: 0.5;
  }
}
.star-field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 700px;
  -webkit-perspective: 700px;
  z-index: 1;
}
.star-field .layer {
  box-shadow: -411px -476px #cccccc, 777px -407px #d4d4d4, -387px -477px #fcfcfc, -91px -235px #d4d4d4, 491px -460px #f7f7f7, 892px -128px #f7f7f7, 758px -277px #ededed, 596px 378px #cccccc, 647px 423px whitesmoke, 183px 389px #c7c7c7,
      524px -237px #f0f0f0, 679px -535px #e3e3e3, 158px 399px #ededed, 157px 249px #ededed, 81px -450px #ebebeb, 719px -360px #c2c2c2, -499px 473px #e8e8e8, -158px -349px #d4d4d4, 870px -134px #cfcfcf, 446px 404px #c2c2c2,
      440px 490px #d4d4d4, 414px 507px #e6e6e6, -12px 246px #fcfcfc, -384px 369px #e3e3e3, 641px -413px #fcfcfc, 822px 516px #dbdbdb, 449px 132px #c2c2c2, 727px 146px #f7f7f7, -315px -488px #e6e6e6, 952px -70px #e3e3e3,
      -869px -29px #dbdbdb, 502px 80px #dedede, 764px 342px #e0e0e0, -150px -380px #dbdbdb, 654px -426px #e3e3e3, -325px -263px #c2c2c2, 755px -447px #c7c7c7, 729px -177px #c2c2c2, -682px -391px #e6e6e6, 554px -176px #ededed,
      -85px -428px #d9d9d9, 714px 55px #e8e8e8, 359px -285px #cfcfcf, -362px -508px #dedede, 468px -265px #fcfcfc, 74px -500px #c7c7c7, -514px 383px #dbdbdb, 730px -92px #cfcfcf, -112px 287px #c9c9c9, -853px 79px #d6d6d6,
      828px 475px #d6d6d6, -681px 13px #fafafa, -176px 209px #f0f0f0, 758px 457px #fafafa, -383px -454px #ededed, 813px 179px #d1d1d1, 608px 98px whitesmoke, -860px -65px #c4c4c4, -572px 272px #f7f7f7, 459px 533px #fcfcfc,
      624px -481px #e6e6e6, 790px 477px #dedede, 731px -403px #ededed, 70px -534px #cccccc, -23px 510px #cfcfcf, -652px -237px whitesmoke, -690px 367px #d1d1d1, 810px 536px #d1d1d1, 774px 293px #c9c9c9, -362px 97px #c2c2c2,
      563px 47px #dedede, 313px 475px #e0e0e0, 839px -491px #e3e3e3, -217px 377px #d4d4d4, -581px 239px #c2c2c2, -857px 72px #cccccc, -23px 340px #dedede, -837px 246px white, 170px -502px #cfcfcf, 822px -443px #e0e0e0, 795px 497px #e0e0e0,
      -814px -337px #cfcfcf, 206px -339px #f2f2f2, -779px 108px #e6e6e6, 808px 2px #d4d4d4, 665px 41px #d4d4d4, -564px 64px #cccccc, -380px 74px #cfcfcf, -369px -60px #f7f7f7, 47px -495px #e3e3e3, -383px 368px #f7f7f7, 419px 288px #d1d1d1,
      -598px -50px #c2c2c2, -833px 187px #c4c4c4, 378px 325px whitesmoke, -703px 375px #d6d6d6, 392px 520px #d9d9d9, -492px -60px #c4c4c4, 759px 288px #ebebeb, 98px -412px #c4c4c4, -911px -277px #c9c9c9;
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.star-field .layer:nth-child(1) {
  animation: sf-fly-by-1 5s linear infinite;
}
.star-field .layer:nth-child(2) {
  animation: sf-fly-by-2 5s linear infinite;
}
.star-field .layer:nth-child(3) {
  animation: sf-fly-by-3 5s linear infinite;
}

.content{
    display: block;
}

.middle-outer{
    height: 90vh;
}

.centered{
    text-align: center;
}

.txt-centered{
  text-align: center;
}

.right{
    text-align: right;
}

.middle-item{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.img-container {
  position: relative;
  text-align: center;
  color: white;
  min-height: 150px;
}

.text-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-font{
    font-size: 50pt;
}

.bolder {
    font-weight: 1000
}

.card-container{
    display: flexbox
}

.card-title__title{
    font-size: 20pt
}

.card-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    cursor: pointer;
    display: flex;
    align-items: center;
}
  
.custom-card {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  padding: 16px;
  border-radius: 4px;
  background: #fff;
  color: rgba(0,0,0,.87);
  width: auto;
  border-radius: 10px;
}

.link{
  cursor: pointer;
  color: #5d0070;
}

.content-area__title{
  margin: 50px 30px 100px 30px;
}
.content-area__main{
  margin-top: 20px;
}
.content-container{
  min-height: 600px;
  text-align: center;
}
.action-area{
  display: block;
  text-align: right;
  margin-bottom: 30px;
}
.full-width{
  width: 100%;
}
.mat-form-field__90{
  width: 90%;
}
.mat-form-field__80{
  width: 80%;
}
.card-title{
  display: block;
  margin-bottom: 30px;
  font-size: 20pt;
}
h3, .menu-toggle, .user-profile, .mat-tab-labels{
  color: #fff;
}
.mat-tab-label-content{
}
.card-subtitle{
  display: block;
  margin-bottom: 10px;
  font-size: 13pt;
}
.spinner__container{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 999;
}
.spinner__item{
  margin: auto;
  top: 50%;
}
.pointer{
  cursor: pointer;
}
.text-icon{
  display: flex;
  align-items: center;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent,
.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab{
    background-color: #004a9f;
    color: #fff;
}

.mat-dialog-container{
  background: #fff;
}

.btn-custom{
  background-color: dodgerblue;
  color: #fff;
}

.custom-select{
  width: 200px;
}

.pill-mime{
  padding: 2px 10px;
  margin-top: 10px;
  background: lightskyblue;
  border-radius: 5px;
  font-size: 9pt;
}

.gradient{
  border-radius: 10px;
  background: #ede5cc;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.cal-event-title {
  color: #000 !important;
}

.container-img {
  position: relative;
  text-align: center;
  color: white;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  width: 100%;
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  width: 100%;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  width: 100%;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  width: 100%;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.centered-bottom {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  transform: translate(-50%, -50%);
}

.centered-top{
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  transform: translate(-50%, -50%);
}

.centered-right {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}

.centered-right-2{
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translate(-50%, -50%);
}

.pill-header{
  padding: 2px 10px;
  background: rgb(13, 137, 213);
  color: #fff;
  font-size: 9pt;
  border-radius: 10px;
}

.pill-global{
  padding: 5px 15px;
  background: rgb(13, 137, 213);
  color: #fff;
  font-size: 10pt;
  border-radius: 10px;
}

.pill-pending{
  background: rgb(223, 179, 57);
}

.pill-approved{
  background: rgb(56, 211, 76);
}

.pill-rejected{
  background: rgb(233, 60, 51);
}

.pill-download{
  background: rgb(173, 182, 245);
}

.margin-top-10{
  margin-top: 10px;
}

.bg-black {
  background: #000;
}

.file-fixed-size{
  font-size: 40pt;
}

.pdf{
  color: #FA0404;
}

.word{
  color: #0e27e3;
}

.excel{
  color: #089c37;
}

#zmmtg-root{
  display: none;
  background: transparent !important;
  color: #FFF;

  #content_container{
    // background: transparent !important;
    // margin: auto;
    // color: #FFF !important;
    // width: 720px !important;
    // height: 450px !important;
    // margin-top: 200px;
  }
}

.br-15{
  border-radius: 15px;
}

.fs-date{
  font-size: 11pt !important;
}

.h-300{
  height: 300px;
}

.h-400{
  height: 400px;
}

.h-200{
  height: 200px;
}

.h-150{
  height: 150px;
}

.h-470{
  height: 470px;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.web-container{
  height: calc(100vh - 150px);
}

.blink-overlap {
  animation: blinker-warning-smooth 2s linear infinite;
}

.blink-pay-now {
  animation: blinker-warning-hard 2s linear infinite;
}

@keyframes blinker-warning-smooth {
  50% {
    // opacity: 0;
    background: yellow;
  }
}

@keyframes blinker-warning-hard {
  50% {
    // opacity: 0;
    background: orange;
  }
}
// mat-paginator{
//   color: #fff;
// }
// .mat-paginator-icon {
//   fill: #FFF !important;
// }

.mat-option-text{
  background: #fff;
  padding: 10px;
}

.carousel-inner{
  border-radius: 10px;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  // -ms-transform: scale(2); /* IE */
  // -moz-transform: scale(2); /* FF */
  // -webkit-transform: scale(2); /* Safari and Chrome */
  // -o-transform: scale(2); /* Opera */
  // transform: scale(2);
  padding: 10px;
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext
{
  /* Checkbox text */
  font-size: 110%;
  display: inline;
}

.title-left{
  text-align: left;
}

.image-viewer__panel .mat-dialog-container {
  text-align: center;
  padding: 0px;
  overflow: hidden;
  background-color: transparent;
}

.image-viewer__panel {
  max-width: 80vw !important;
}

.dialog-panel {
  width: 100%;
  max-width: 40vw !important;
}

@media only screen and (max-device-width: 480px) {
  .dialog-panel {
    width: 100%;
    max-width: 100vw !important;
  }
}

@media only screen and (max-device-width: 850px) {
  .dialog-panel {
    width: 100%;
    max-width: 80vw !important;
  }
}

.fixed-height-overflow{
  height: calc( 100vh - 230px );
  overflow: auto;
}

.fixed-height-overflow-2{
  height: calc( 100vh - 300px );
  overflow: auto;
}

.mat-tab-label{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 20vw;
  height: 4vh !important;
  // background-color: #000000;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  opacity: 1;
  color: #000000;
}

/* Styles for the active tab label */

// change any styles you want for active tab
.mat-tab-label.mat-tab-label-active {
  background-color: rgb(254, 189, 67);
  color: #000000;
}

.class-open {
  font-size: 13pt;
  padding: 5px 10px;
  background: #32ad36;
  color: #fff;
  border-radius: 10px;
}

.class-preparing {
  font-size: 13pt;
  padding: 5px 10px;
  background: #fd9a18;
  color: #fff;
  border-radius: 10px;
}

.class-upcoming {
  font-size: 13pt;
  padding: 5px 10px;
  background: #20a0f5;
  color: #fff;
  border-radius: 10px;
}

.class-ended {
  font-size: 13pt;
  padding: 5px 10px;
  background: #f0342a;
  color: #fff;
  border-radius: 10px;
}

.class-open-schedule {
  font-size: 9pt;
  padding: 2px 5px;
  background: #32ad36;
  color: #fff;
  border-radius: 5px;
}

.class-preparing-schedule {
  font-size: 9pt;
  padding: 2px 5px;
  background: #fd9a18;
  color: #fff;
  border-radius: 5px;
}

.class-upcoming-schedule {
  font-size: 9pt;
  padding: 2px 5px;
  background: #20a0f5;
  color: #fff;
  border-radius: 5px;
}

.class-ended-schedule {
  font-size: 9pt;
  padding: 2px 5px;
  background: #f0342a;
  color: #fff;
  border-radius: 5px;
}

.flex-schedule{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cal-month-view .cal-open-day-events {
  color: #000;
  background-color: rgb(254, 212, 132);
  box-shadow: inset 0 0 15px 0 rgb(0 0 0 / 50%);
  box-shadow: none;

  div{
    padding-bottom: 8px;
    border-bottom: 2px solid #ffa600;
    padding-top: 8px;
  }
}

.tooltip-schedule{
  display: flex;
  background: rgb(254, 212, 132);
  padding: 10px 15px;
  border-radius: 10px;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 10px;
  z-index: 9999;
}

.title-schedule{
  // margin-left: 18px;
}

.title-color-start{
  color: green;
}

.title-color-end{
  color: red;
}

.cal-time-events, .cal-days {
  height: calc( 100vh - 300px );
  overflow: auto;
}

.class-pill-status{
  width: auto;
  display: flex;
  align-items: center;
  justify-content: end;
}

//Mobile View Styling
.mobile-view {
  display: none !important;
}

@media only screen and (max-device-width: 480px) {
  .web-view {
    display: none !important
  }

  .mobile-view {
    display: flex !important;
  }
  
  .cal-header {
    font-size: 0.8rem;
  }

  .cal-week-view .cal-day-headers {
    padding-left: 50px;
  }
  
  .cal-week-view .cal-time-label-column {
    width: 50px;
  }

  .cal-week-view .cal-time {
    width: 50px;
    font-size: 0.8rem;
  }

  .mobile-view {
    .mat-flat-button {
      min-width: 0px;
    }
  }

  .tooltip-schedule {
    font-size: 12px;
    width: 300px;
  }

  .cal-open-day-events {
    font-size: 12px;
  }

  .class-pill-status {
    width: auto;
  }

  .topic {
    font-size: 12px;
  }

  .mat-tab-label {
    width: 150px;
  }

  .mat-tab-labels {
    justify-content: center !important;
  }
  
  .mat-tab-label-content {
    font-size: 13px !important;
  }

  .pill-global {
    padding: 5px;
    font-size: 8pt;
    border-radius: 10px;
  }

  .centered-right-mobile {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  mat-form-field{
    width: 100%;
  }
  
  .label-header-main{
    // margin-left: auto;
  }

  .show-hide {
    display: none;
  }

  .show-content {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
  }

  .container-xxl-outside{
    padding: 0;
  }

  .login-padding{
    padding: 15px !important;
  }

  .teacher{
    min-width: 0 !important;
  }

  .dialog-panel{
    max-width: 95vw !important;
  }

  .cal-days {
    height: 100%;
  }

  // .filter__div {
  //   margin-right: 4vw;
  // }
}

.mat-dialog-container{
  overflow: auto !important;
  max-height: 90vh !important;
}

.show-content {
  display: none;
}

.mat-form-field{
  margin-left: 5px !important;
}

.label-header-main{
  margin-right: 15px;
}

.filter-dialog-custom {
  .mat-dialog-container {
    padding: 0px;

    .modal-header {
      padding: 10px !important;
    }
  }
}

.bottom-centered {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.event-list__box {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px;
}

.show-content-main{
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

@keyframes fade-animation {
  0%, 100% {
    background-color: #cff4fc; /* Initial color */
  }
  50% {
    background-color: #e9f7fa; /* Color change */
  }
}

.fade-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fade-animation 1s infinite;
}

.fade-effect {
  position: relative;
  display: inline-block;
}

.text-container {
  position: relative;
  z-index: 1;
}

.inline-block{
  display: inline-block;
}

.menu{
  padding: 10px 0px 0px 0px !important;
}
.calorie{
  padding: 10px 0px 10px 0px !important;
}

th{
  background: #ede5cc !important;
}