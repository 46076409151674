// card 
.card{
    border-color: var(--border-color);
    background-color: var(--card-color);
    &.card-callout{
        border-left-width: .4rem;
        border-left-color: var(--primary-color);
    }
    .card-header{
        background-color: transparent;
        border-bottom: none;
    }
}

// Experience widgets
.resume-box {
    margin: 0;
    padding: 30px 20px;
    list-style: none;

    li {
        position: relative;
        padding: 0 20px 0 60px;
        margin: 0 0 30px;

        &:last-child {
            margin-bottom: 0;
        }
        &:after {
            content: "";
            position: absolute;
            border-left: 1px dashed var(--primary-color);
            top: 0px;
            left: 20px;
            bottom: 0;
        }
    }
    .icon {
        color: var(--primary-color);
        background: var(--card-color);
        position: absolute;
        border: 1px dashed;
        width: 40px;
        height: 40px;
        left: 0;
        right: 0;
        line-height: 40px;
        z-index: 1;
        border-radius: 50%;
    }
    .time {
        background: var(--primary-color);
        color: $white;
        display: inline-block;
        font-size: 10px;
        padding: 2px 10px;
        margin-bottom: 12px;
        border-radius: 20px;
    }
}