.rightchatbox {
    background: var(--primary-color);
    border-radius: 0.25rem;
    #friends{
        height: 570px;
        @include overflow(scroll);
    }
    .friend {
      height: 70px;
      border-bottom: 1px solid var(--color-900);
      position: relative;
      display: flex;
      &:hover {
        background: var(--primary-color);
        cursor: pointer;
      }
      img {
        width: 40px;
        border-radius: 50%;
        margin: 15px;
        float: left;
      }
      p {
        padding: 15px 0 0 0;
        float: left;
        width: 220px;
        strong {
          font-weight: 600;
          font-size: 15px;
          color: var(--white-color);
          display: block;
        }
        span {
          font-size: 13px;
          font-weight: 400;
          color: var(--white-color);
        }
      }
      .status {
        background: #26c281;
        border-radius: 50%;
        width: 9px;
        height: 9px;
        position: absolute;
        top: 31px;
        right: 17px;
      }
      .status.away {
        background: #ffce54;
      }
      .status.inactive {
        background: #eaeef0;
      }
    }
    .floatingImg {
      width: 40px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 50% !important;
      border: 3px solid #fff;
      transform: translateX(-50%);
    }
    #search {
      width: 100%;
    }
    #searchfield {
      background: #e3e9ed;
      margin: 21px 0 0 55px;
      border: none;
      padding: 0;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400px;
      color: #8198ac;
      &:focus {
        outline: 0;
      }
    }
    #chatview {
      width: 100%;
      height: 600px;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      background: var(--primary-color);
      overflow: hidden;
    }
    #profile {
      height: 153px;
      overflow: hidden;
      text-align: center;
      color: #fff;
      .avatar {
        width: 68px;
        border: 3px solid #fff;
        margin: 23px 0 0;
        border-radius: 50%;
      }
      p {
        font-weight: 600;
        font-size: 15px;
        margin: 118px 0 -1px;
        opacity: 0;
        -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
      }
      p.animate {
        margin-top: 97px;
        opacity: 1;
        -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
        transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
      }
      span {
        font-weight: 400;
        font-size: 11px;
      }
    }
    .p1 {
      #profile {
        background: #fff
          url("../../../assets/images/gallery/8.jpg")
          0 0 no-repeat;
          background-size: cover;
      }
    }
    #chat-messages {
      opacity: 0;
      margin-top: 30px;
      width: 100%;
      height: 500px;
      overflow-x: hidden;
      @include overflow(scroll);
      padding-right: 20px;
      -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
      -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
      -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
      -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
      transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
      label {
        color: #aab8c2;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        margin: 15px 0;
        width: 100%;
        display: block;
      }
      div.message {
        padding: 0 0 30px 58px;
        clear: both;
        margin-bottom: 45px;
      }
      div.message.right {
        padding: 0 58px 30px 0;
        margin-right: -19px;
        margin-left: 19px;
        img {
          float: right;
          margin-left: 0;
          margin-right: -38px;
        }
        .bubble {
          float: right;
          border-radius: 5px 5px 0px 5px;
        }
      }
      .message {
        img {
          float: left;
          margin-left: -38px;
          border-radius: 50%;
          width: 30px;
          margin-top: 12px;
        }
      }
    }
    #chat-messages.animate {
      opacity: 1;
      margin-top: 0;
    }
    .message {
      .bubble {
        background: #f0f4f7;
        font-size: 13px;
        font-weight: 600;
        padding: 12px 13px;
        border-radius: 5px 5px 5px 0px;
        color: #8495a3;
        position: relative;
        float: left;
      }
    }
    .bubble {
      span {
        color: #aab8c2;
        font-size: 11px;
        position: absolute;
        right: 0;
        bottom: -22px;
      }
    }
    div.message.right {
    }
    #sendmessage {
      position: absolute;
      bottom: 0;
      right: 0px;
      width: 100%;
      background: var(--primary-color);
      overflow: hidden;
    }
    #close {
      position: absolute;
      top: 8px;
      opacity: 0.8;
      left: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .cx {
      background: #fff;
      position: absolute;
      width: 0px;
      top: 15px;
      right: 15px;
      height: 3px;
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      -ms-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .cy {
      background: #fff;
      position: absolute;
      width: 0px;
      top: 15px;
      right: 15px;
      height: 3px;
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      -ms-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .cx.s1 {
      right: 0;
      width: 20px;
      -webkit-transition: all 100ms ease-out;
      -moz-transition: all 100ms ease-out;
      -ms-transition: all 100ms ease-out;
      -o-transition: all 100ms ease-out;
      transition: all 100ms ease-out;
      right: 0;
      width: 20px;
      -webkit-transition: all 100ms ease-out;
      -moz-transition: all 100ms ease-out;
      -ms-transition: all 100ms ease-out;
      -o-transition: all 100ms ease-out;
      transition: all 100ms ease-out;
    }
    .cy.s1 {
      right: 0;
      width: 20px;
      -webkit-transition: all 100ms ease-out;
      -moz-transition: all 100ms ease-out;
      -ms-transition: all 100ms ease-out;
      -o-transition: all 100ms ease-out;
      transition: all 100ms ease-out;
    }
    .cy.s2 {
      -ms-transform: rotate(50deg);
      -webkit-transform: rotate(50deg);
      transform: rotate(50deg);
      -webkit-transition: all 100ms ease-out;
      -moz-transition: all 100ms ease-out;
      -ms-transition: all 100ms ease-out;
      -o-transition: all 100ms ease-out;
      transition: all 100ms ease-out;
    }
    .cy.s3 {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 100ms ease-out;
      -moz-transition: all 100ms ease-out;
      -ms-transition: all 100ms ease-out;
      -o-transition: all 100ms ease-out;
      transition: all 100ms ease-out;
    }
    .cx.s2 {
      -ms-transform: rotate(140deg);
      -webkit-transform: rotate(140deg);
      transform: rotate(140deg);
      -webkit-transition: all 100ms ease-out;
      -moz-transition: all 100ms ease-out;
      -ms-transition: all 100ease-out;
      -o-transition: all 100ms ease-out;
      transition: all 100ms ease-out;
    }
    .cx.s3 {
      -ms-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      -webkit-transition: all 100ease-out;
      -moz-transition: all 100ms ease-out;
      -ms-transition: all 100ms ease-out;
      -o-transition: all 100ms ease-out;
      transition: all 100ms ease-out;
    }
}
